import axios from "axios";
import { getHeaders } from "../../../utils/methods";
import { urlCondominiumExpense } from "../../../utils/urls";

const token = localStorage.getItem('Authorization');
export const getCondominiumExpencePerPeriod = async (period)=>{
    try{
        const header = getHeaders(token);
        const consulta = await axios.get(urlCondominiumExpense,header);
        const response = consulta.data.data;
        const filterPeriod = response.filter(itm=>itm.date.slice(0,7) === period);
        return filterPeriod;
    }catch(e){
        throw Error(e);
    }
}