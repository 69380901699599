import "../pages/general.css"
import 'moment/locale/es';
import "./GastosPage/styles/GastosPage.scss";

import React, {useContext, useEffect, useState} from 'react';
import ModalDetails from "../components/ModalDetails";
import ModalDetails2 from "../components/ModalDetails2";
import ModalDetails5 from "../components/ModalDetails5";
import ModalInsertar from "../components/ModalInsertar";
import axios from "axios"
import jsPDF from "jspdf"
import moment from "moment"
import { userContext } from '../context/UserContext';
import DetailGastosComunesModal from "./modals/gastos/detailGastosComunes";
import { getHeaders } from "../utils/methods";
import { urlAccountBank, urlGetRecipt, urlMyInvoices, urlTotalLightExpediture } from "../utils/urls";
import ColumnTable from "./GastosPage/components/Column";
import { getExpeditureInPeriod, getTotalCondominiumPercent, getTotalLightExpediture, getTotalWaterExpediture } from "../utils/financialMethods";
import ModalBodyDetailGastosPage from "./GastosPage/modalBody/modal.body.detail";
import ModalBodyDownload from "./GastosPage/modalBody/modal.body.downLoad";
import Loader from "../components/Loader";
import { getTotalWaterExpediturePerPeriod, getWaterExpediture } from "./GastosPage/services/waterServices";
import { getLightExpediturePerPeriod, getTotalLightExpediturePerPeriod } from "./GastosPage/services/lightServices";
import { getCondominiumExpencePerPeriod } from "./GastosPage/services/condominiumExpense";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    width: "95%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(4,4 ),
    // margin: "10px",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  //   display: "grid"
  },
  iconos:{
    cursor: 'pointer'
  }, 
  inputMaterial:{
    width: '100%'
  }
}));
function Gastos() {

  const styles= useStyles();
  const [data, setData] = useState([])
  const [sumadata, setSumaData] = useState(0)
  const [sumadata1, setSumaData1] = useState(0)
  const [dataLuz, setDataLuz] = useState([])
  const [selectedImage, setSelectedImage] = useState();
  const [selectedFilesPost, setSelectedFilesPost] = useState();
  const [pathImg, setPathImg] = useState()

  const [dataAgua, setDataAgua] = useState([])
  const [totalGastos, setTotalGastos] = useState(0)
  const [bankaccount, setbankaccount] = useState([]);
  const [error, setError] = useState(false)

  const [totalGastosLuz, setTotalGastosLuz] = useState({})
  const [totalGastosAgua, setTotalGastosAgua] = useState({})
  const [dataProperty, setDataProperty] = useState({})
  const { dataUser, setdataUser } = useContext(userContext);
  const [Start, setStart] = useState(false);
  const [mesSelected, setMesSelected] = useState("");
  const [yearSelected, setYearSelected] = useState("");
  const fecha = new Date()
  const [showModalDetails, setShowModalDetails] = useState(false);
  const [showModalDetails5, setShowModalDetails5] = useState(false);
  const [showModalDetails4, setShowModalDetails4] = useState(false);
  const [showModalDetails3, setShowModalDetails3] = useState(false);
  const [exito, setExito] = useState(false);
  const [exito1, setExito1] = useState(false);
  const fecha1 =  moment(fecha).format("YYYY")
  const fecha2 =  moment(fecha).format("MM")
  const [respuesta, setRespuesta] = useState("");
  const [allInfoInvoices, setAllInfoInvoices] = useState([]);
  //Controlador de carga
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);

  const token = localStorage.getItem('Authorization');

  const [info, setInfo] = useState({
    propertyId: "",
    year: fecha1,
    month:fecha2
  });
  const [info2, setInfo2] = useState({
    propertyId: "",
    file: "",
    date:""
  });


const{file} = info2;

const abrirCerrarModalDetails=()=>{
  setShowModalDetails(!showModalDetails);
}
const abrirCerrarModalDetails5=()=>{
  setShowModalDetails5(!showModalDetails5);
}
const abrirCerrarModalDetails4=()=>{
  setShowModalDetails4(!showModalDetails4);

}
const abrirCerrarModalDetails3=()=>{
  setShowModalDetails3(!showModalDetails3);
}

const buscarUnitCostLuz = async() => {
  const headers = getHeaders(token)
  const rtdo = await axios.get(urlTotalLightExpediture, headers);
}
const buscarbankaccount = async() => {            
  const headers = getHeaders(token);
  const rtdo = await axios.get(urlAccountBank, headers)
  setbankaccount(rtdo.data.data)
}
const handleChangeInsert = (e) => {

  setInfo({
      ...info,
      [e.target.name]: e.target.value
  })
  
}
const imageChange = (e) => {
  if (e.target.files && e.target.files.length > 0) {
    setSelectedImage(e.target.files[0]);
    setSelectedFilesPost(e.target.files[0])
  }
};
const removeSelectedImage = () => {
  setSelectedImage();
};
  /**
   * @description Seccion para controlar los estados de cuenta 
   */
  /**
   * @function getAllInvoices
   * @description Obtenemos todas la facturas de un propietario
   */
  const getAllInvoices = async ()=>{
    try{
      const headers = getHeaders(token);
      const body = {
        propertyId: dataUser?.properties?.[0]?.id
      };
      const consulta = await axios.post(urlMyInvoices, body, headers);
      if(consulta.status === 200){
        setAllInfoInvoices(consulta.data.data);
      }
    }catch(e){  
      alert("Error al obtener todos los recibos");
      console.log("Error getAllInvoices",e);
    }
  }
  /**
   * @function getInvoicesPerPeriod
   * @description buscar todas las facturas por periodo.
   */
  const getInvoicesPerPeriod = async () =>{
    setStart(false);
    try{
      const headers = getHeaders(token);
      const body = {
        propertyId: dataUser?.properties?.[0]?.id,
        year: parseInt(yearSelected),
        month:parseInt(mesSelected)
      };
      const consulta = await axios.post(urlGetRecipt, body, headers);
      if(consulta.status === 201){
        const data = consulta.data.data;
        const totalCondom = getTotalCondominium(data?.condoExpense); //Total del condominio por mes
        const periodParam = {
          participation:dataProperty?.participation,
          total: totalCondom,
          lightConsume: data?.lightExpenditure?.[0]?.consume,
          totalLight:data?.totalLightExpenditure?.[0]?.consume,
          lightTransactionCost: data?.lightExpenditure?.[0]?.transactionCost,
          waterConsume:data?.waterExpenditure?.[0]?.consume,
          totalWater: data?.totalWaterExpenditure?.[0]?.consume,
          waterTransactionCost: data?.waterExpenditure?.[0]?.transactionCost
        }
        const totalExpediture = getExpeditureInPeriod(periodParam);

        const result = {
          invoice:[{
            date: data?.lightExpenditure?.[0]?.date,
            total: totalExpediture,
            state:{
              name: "Pendiente"
            }
          }]
        };

        setAllInfoInvoices(result);

        setData(data?.condoExpense);
        setDataLuz(data?.lightExpenditure[0]);
        setDataAgua(data?.waterExpenditure[0]);
        setTotalGastosAgua(data.totalWaterExpenditure?.[0]?.consume);
        setTotalGastosLuz(data.totalLightExpenditure?.[0]?.consume);
        setTotalGastos(totalExpediture);
        setStart(true);
      }
    }catch(e){
      alert("Error al hacer la busqueda por periodo");
      console.log("Error Buscar Periodo:",e);
    }
  }
  /**
   * @function getTotalCondominium
   * @description Obtener el total del periodo del mes
   * @param {object} data 
   */
  const getTotalCondominium = (data=[])=>{
    let suma = 0;
    for (let i = 0; i < data.length; i++) {
      suma = suma + parseFloat(data[i].amount) + parseFloat(data[i].transactionCost);
    }
    return suma;
  }
  /**
   * @function resetDetailsParams
   * @description Se resetea la data de los recibos
   */
  const resetDetailsParams = ()=>{
    setData({});
    setDataLuz({});
    setDataAgua({});
    setTotalGastosAgua(0);
    setTotalGastosLuz(0);
    setTotalGastos(0);
    setSumaData1(0); //Total del condominio
  }
  /**
   * Busqueda de los recibos pendientes y pagados
   * @param {object} EventForm 
   */
  const getInvoices = async() => {
    resetDetailsParams();
    setIsLoading(true);
    setStart(false);
    if(mesSelected === "" && yearSelected === ""){
      await getAllInvoices();
    }else if(mesSelected !== "" && yearSelected !== ""){
      await getInvoicesPerPeriod();
    }else{
      alert("Debe seleccionar un mes y un año o no seleccionar ninguno para ver el historíco");
    }
    setIsLoading(false);
  }
  /**
   * Inicialización de la información
   */
  useEffect(() => {
    setdataUser(JSON.parse(localStorage.getItem('user')));
    setDataProperty(JSON.parse(localStorage.getItem('propiedad')));
    buscarUnitCostLuz();
    buscarbankaccount();
    getInvoices();
    }, []);

  useEffect(() => {
    let suma = 0
 
    if (data.length >= 1 && dataLuz !== {} && dataAgua !== {}  && data[0].approved === "1") {
     
      for (let i = 0; i < data.length; i++) {
      suma = suma + parseFloat(data[i].amount) + parseFloat(data[i].transactionCost)
     }
     setSumaData1(suma)
     setSumaData((suma * parseFloat(dataProperty.participation))  / 100)
     setTotalGastos(((suma * parseFloat(dataProperty.participation))  / 100) + dataLuz.consume  * totalGastosLuz  + parseFloat(dataLuz.transactionCost) + dataAgua.consume  * totalGastosAgua + parseFloat(dataAgua.transactionCost) );
    }

    setExito1(true)


  }, [totalGastosLuz]);

  const peticionPost=async()=>{
    const f = new FormData() 

    const fecha3 = yearSelected+"-"+mesSelected+"-10" 
            
        f.append("file", selectedFilesPost)
        f.append("date", fecha3 )
        f.append("propertyId", dataProperty.id)

      const headers = {
        'Content-type': 'multipart/form-data',
        'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),
  
    }
  
      const url1= "${urlBase}/payment"
        await axios.post(url1, f, {headers})
        .then(response=>{

          setSelectedFilesPost([])
          setRespuesta("Archivo cargado correctamente")
          setExito(true);
        }).catch(error=>{
          console.log(error);
  
          setSelectedFilesPost([])
        })
  
    }

  const onSubmitInsertar = (e) => {
    e.preventDefault();


      setError(false);

      peticionPost()
      setPathImg()
      setSelectedImage()
      setInfo2({
        propertyId: "",
        file: "",
        date:""
      });

      abrirCerrarModalDetails3()
      abrirCerrarModalDetails()
     }

     const generatePDF = () => {
       let doc = new jsPDF("p", "pt","a4" )
       doc.html(document.querySelector("#content"), {
         callback: function(pdf){
           pdf.save("mypdf.pdf")
         }
       } )

     }

        const mes = [
            { value: "01", label: 'Enero' },
            { value: "02", label: 'Febrero' },
            { value: "03", label: 'Marzo' },
            { value: "04", label: 'Abril' },
            { value: "05", label: 'Mayo' },
            { value: "06", label: 'Junio' },
            { value: "07", label: 'Julio' },
            { value: "08", label: 'Agosto' },
            { value: "09", label: 'Septiembre' },
            { value: "10", label: 'Octubre' },
            { value: "11", label: 'Noviembre' },
            { value: "12", label: 'Diciembre' }
           
          ]

        const years = [
            { value: 2022, label: '2022' },
            { value: 2023, label: '2023' },
            { value: 2024, label: '2024' },
            { value: 2025, label: '2025' },
            { value: 2026, label: '2026' },
            { value: 2027, label: '2027' },
            { value: 2028, label: '2028' },
            { value: 2029, label: '2029' },
            { value: 2030, label: '2030' },
            { value: 2031, label: '2031' }
     
          ]
          
  const bodyInsertar = (
    <div >
      <div className="estilosmodalDetails11">

        <form action="" onSubmit={onSubmitInsertar}>

          <div className={styles.modal}>
            <h1 className="my-5 text-center">Adjuntar pago</h1>

            {error ? <h4 className=" text-red-700">Completar todos los campos (*) del formulario</h4> : null}


          
            <div className='mt-5'>
              {/* <label>Choose File to Upload: </label> */}
              <input type="file" onChange={imageChange} id="file" name='image' />
              <div className="label-holder">
                <label htmlFor="file" className="label1">
                  <i className="material-icons">attach_file</i>
                </label>
              </div>
            </div> <br />


            {selectedImage && (
              <div className='eliminarImg my-4 mx-5'>
                <img
                  src={URL.createObjectURL(selectedImage)}
                  className='foto1'
                  alt="Thumb"
                />
                <button onClick={removeSelectedImage} style={styles.delete} className="mt-3 mx-3">
                  Eliminar
                </button>
              </div>
            )}

            <br /><br />
            <div className="d-flex justify-content-around">
              <button className="btn1" type="submit" ><h5>Insertar</h5></button>
              <button className="btn1" onClick={abrirCerrarModalDetails3}> <h5>Cancelar</h5></button>
            </div>
          </div>
        </form>



      </div>
    </div>
  )

  /**
   * Controladores del modal de detalles
   */
  const toggleDetailModal = async (date)=>{
    if(!Start)
      resetDetailsParams();
    
    setIsLoadingDetail(true);
    try{
      if(sumadata1 === 0 || totalGastosAgua === 0 || totalGastos === 0 || totalGastosLuz === 0){
        const periodo = date.slice(0,7);
        const propertyId = dataUser?.properties?.[0]?.id;
        const totalWater = await getTotalWaterExpediturePerPeriod(periodo);
        const waterExpediture = await getWaterExpediture(periodo,propertyId);
        const totalLight = await getTotalLightExpediturePerPeriod(periodo);
        const lightExpenditure = await getLightExpediturePerPeriod(periodo, propertyId);
        const condominiumExpence = await getCondominiumExpencePerPeriod(periodo);
        /**
         * Seccion de calculos
         */
        const totalCondom = getTotalCondominium(condominiumExpence); //Total del condominio por mes
        const periodParam = {
          participation:dataProperty?.participation,
          total: totalCondom,
          lightConsume: lightExpenditure?.consume,
          totalLight: totalLight,
          lightTransactionCost: lightExpenditure?.transactionCost,
          waterConsume: waterExpediture?.consume,
          totalWater: totalWater,
          waterTransactionCost: waterExpediture?.transactionCost
        }
        const totalExpediture = getExpeditureInPeriod(periodParam);
        //Guardado de los totales
        setSumaData1(totalCondom);
        setData(condominiumExpence);
        setDataLuz(lightExpenditure);
        setDataAgua(waterExpediture);
        setTotalGastosAgua(totalWater);
        setTotalGastosLuz(totalLight);
        setTotalGastos(totalExpediture);
        //Mostrar el modal
        setShowModalDetails(!showModalDetails);
      }else{
        setShowModalDetails(!showModalDetails);
      }
    }catch(e){
      alert("Error al obtener los datos");
      console.log("Error detail:",e);
    }
    setIsLoadingDetail(false);
  }
  /**
   * Controladores de modales
   * @returns {void}
   */
  /**
   * Obtener el cuerpo del recibo del periodo
   */
  const getBodyDetailRecibo = ()=>{
    return(
      <ModalBodyDetailGastosPage 
        date={dataAgua.date}
        manzana={dataProperty.block}
        lote={dataProperty.lot}
        totalCondominio={sumadata1}
        totalIndividual={getTotalCondominiumPercent(dataProperty.participation,sumadata1)}
        totalAgua={getTotalWaterExpediture(dataAgua.consume,totalGastosAgua,dataAgua.transactionCost)}
        totalLuz={getTotalLightExpediture(dataLuz.consume,totalGastosLuz,dataLuz.transactionCost)}
        dataBank={bankaccount}
        toggleFunction={toggleDetailModal}
        adjuntFunction={abrirCerrarModalDetails3}
        downloadRecibeFuntion={abrirCerrarModalDetails5}
        resumeExpeditureFuncion={abrirCerrarModalDetails4}
      />
    )
  }
  /**
   * Obtener el cuerpo del recibo para descargar
   */
  const getBodyDetailReciboToPDF = ()=>{
    return(
      <ModalBodyDownload 
        date={dataAgua.date}
        manzana={dataProperty.block}
        lote={dataProperty.lot}
        totalCondominio={sumadata1}
        totalIndividual={getTotalCondominiumPercent(dataProperty.participation,sumadata1)}
        totalAgua={getTotalWaterExpediture(dataAgua.consume,totalGastosAgua,dataAgua.transactionCost)}
        totalLuz={getTotalLightExpediture(dataLuz.consume,totalGastosLuz,dataLuz.transactionCost)}
        toggleFunction={abrirCerrarModalDetails5}
        generatePDFfunction={generatePDF}
      />
    )
  }

    return <div className="Contenedor" >
                  {exito ? <div classs="container p-5">
            <div className="row no-gutters fixed-top">
              <div className="col-lg-5 col-md-12">
                <div className="alert alert-primary fade show" role="alert">
                  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  <h4 className="alert-heading text-center">{respuesta}</h4>
                </div>
              </div>
            </div>
            </div>: null}
      <div className='verde text-center'><h1>ESTADO DE CUENTA</h1></div>


  
        <div className='blanco '>

          <div className="pt-3 bg-secondary text-white">
            <div className="container">
              <h3>Propietario: {dataUser.name} {dataUser.lastName}</h3>
              <div className="d-flex justify-content-between">
                <h6>Manzana: {dataProperty.block}</h6>
                <h6>Lote:{dataProperty.lot}</h6>
              </div>
              <div className="d-flex justify-content-between">
                <h6>Area (m2): {dataProperty.area}</h6>
                <h6>Participacion(%): {dataProperty.participation}</h6>
                        </div>
            </div>

          </div>
          <div className="formstyle py-4">
              <select name="type" className=" selectstyle" onChange={(e)=> setMesSelected(e.target.value)}>

                <option value=""> Mes</option>
                {mes.map(fbb =>
                    <option key={fbb.value} value={fbb.value}>{fbb.label}</option>
                )};
              </select>
              <select name="type" className=" selectstyle" onChange={(e)=> setYearSelected(e.target.value)}>

                <option value=""> Año</option>
                {years.map(fbb =>
                    <option key={fbb.value} value={fbb.value}>{fbb.label}</option>
                )};
              </select>
              <button className="btn1" type="submit" onClick={getInvoices}>Ver</button>
            </div>
            
          <div className="d-flex justify-content-between container mt-3">
            <table className="tableRecibos">
              <tr>
                <th><h4>Periodo</h4></th>
                <th><h4>Total</h4></th>
                <th><h4>Estado</h4></th>
                <th><h4>Recibo</h4></th>
              </tr>
              <tr>
                <td colSpan={4} >
                  <Loader isShow={isLoading} title={"Cargando resultados"}/>
                </td>
              </tr>
              {
                allInfoInvoices?.invoice?.length > 0 && !isLoading ?
                  allInfoInvoices.invoice.map((itm,i)=><ColumnTable key={i} date={itm?.date} total={itm?.total} estado={itm?.state?.name} openRecipt={()=>toggleDetailModal(itm?.date)}/>)
                :null
              }
            </table>
          </div>
          <Loader fullScreen={true} isShow={isLoadingDetail} title={"Cargando detalles del recibo"}/>

            <ModalDetails2
              showModalDetails={showModalDetails}
              functionShow= {abrirCerrarModalDetails}
              info={info}
              bodyDetails={getBodyDetailRecibo()}
            />

            <ModalDetails5
              showModalDetails={showModalDetails5}
              functionShow= {abrirCerrarModalDetails5}
                info={info}
              bodyDetails={getBodyDetailReciboToPDF()}
              />
              <ModalDetails
                showmodalInsertar={showModalDetails4}
                functionShow= {abrirCerrarModalDetails4}
                info={info}
                bodyAgregar={
                  <DetailGastosComunesModal
                    total={sumadata1}
                    data={data}
                    backFunction={abrirCerrarModalDetails4} 
                  />
                }
              />
              <ModalInsertar
            showmodalInsertar={showModalDetails3}
            functionShow= {abrirCerrarModalDetails3}
            handleChangeInsert={handleChangeInsert}
            onSubmitInsertar={onSubmitInsertar}
            error={error}
            bodyInsertar={bodyInsertar}
           
            
            />
    </div>
  </div>;
}

export default Gastos;
