import { useEffect, useState } from "react";
import { money } from "../../../utils/formats"

const ModalBodyDownload = ({
    date,
    manzana,
    lote,
    totalCondominio,
    totalIndividual,
    totalAgua,
    totalLuz,
    toggleFunction,
    generatePDFfunction
})=>{
    /**
     * Controlador de los meses
     */
     const [mesSelected, setMesSelected] = useState(0);
     const [yearSelected, setYearSelected] = useState(0);
     
     useEffect(()=>{
         const initComponent = ()=>{
             const subdivideDate = date.split("-");
             setYearSelected(subdivideDate[0]);
             setMesSelected(subdivideDate[1]);
         }
         initComponent();
     },[])
    return(
    <div>
        <div className="estilosmodalDetails1">
            <div className="container" id="content">
            <div className="row mt-2">
            <h1 className="text-center">Nombre del Condomino</h1>
                <div className="col-12 d-flex justify-content-between">
                    <div className="col-6">
                    <h6 className="text-gray-600">Periodo: {mesSelected}/{yearSelected}</h6>
                    <h6 className="text-gray-600">Proiedad: Mz {manzana} lt {lote}</h6>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <h4 className="text-black">Gastos comunes del Condominio</h4>
                <div className="d-flex justify-content-between mt-1">
                    <h5 className="text-gray-600">Total: { money.format(totalCondominio) } </h5>
                </div>
            </div>
            <div className="row mt-2">
                <h4 className="font-weight-bold text-black">Gastos de la propiedad</h4>
                <div className="d-flex justify-content-between">
                    <h5 className="text-gray-600">Gastos Comunes: </h5>
                    <h5 className="text-gray-600">{ money.format(totalIndividual) }</h5>
                </div>
                <div className="d-flex justify-content-between">
                    <h5 className="text-gray-600">Servicios de Agua: </h5>
                    <h5 className="text-gray-600">{money.format(totalAgua)}</h5>
                </div>
                <div className="d-flex justify-content-between">
                    <h5 className="text-gray-600">Servicios de Luz: </h5>
                    <h5 className="text-gray-600">{money.format(totalLuz)}</h5> 
                </div> 
                <div className="d-flex justify-content-between">
                    <h3 className="text-black">Total a pagar: </h3>
                    <h3 className="text-black">{ money.format(totalIndividual + totalAgua + totalLuz) }</h3>
                </div>  
            </div>
            <div className="row mt-1">
                <div className="col-12">
                    <h6 className="text-gray-600">Cta. Corrientes BCP Soles</h6>
                    <h6 className="text-gray-600">XXX.XXXX.XXXX.XXXXX.XXXX</h6>
                    <h6 className="text-gray-600">Cta. Corrientes BCP Soles</h6>
                    <h6 className="text-gray-600">XXX.XXXX.XXXX.XXXXX.XXXX</h6>
                </div>
            </div>
            </div>
            <div className="d-flex justify-content-center"><button className="btn1" onClick={generatePDFfunction}>Descargar como PDF</button></div>
            <div className="d-flex justify-content-center"><button className="btn1" onClick={()=>toggleFunction()}>Volver</button></div>
        </div>
    </div>
    );
}

ModalBodyDownload.defaultProps = {
    date:"1900-01-01",
    manzana:"0",
    lote:"0",
    totalCondominio:"0",
    totalIndividual:"0",
    totalAgua:"0",
    totalLuz:"0",
    toggleFunction:()=>{},
    generatePDFfunction:()=>{}
}

export default ModalBodyDownload;