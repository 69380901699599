import "../pages/general.css";
import "./styles/Reclamos.scss";
import { Link, NavLink } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ModalDetails2 from "../components/ModalDetails2";
import ModalDetails5 from "../components/ModalDetails5";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { userContext } from "../context/UserContext";
import { urlAvatar, urlBase } from "../utils/urls";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    //   display: "grid"
  },
  iconos: {
    cursor: "pointer",
  },
  inputMaterial: {
    width: "100%",
  },
}));

export default function Reclamos() {
  const [data, setData] = useState([]);
  const calendarRef = useRef(null);
  const { dataUser, setdataUser } = useContext(userContext);
  const [respuesta, setRespuesta] = useState("");
  const [showModalDetails, setShowModalDetails] = useState(false);
  const [showModalDetails5, setShowModalDetails5] = useState(false);
  const [dataProperty, setDataProperty] = useState({});

  const [loading, setLoading] = useState(false);
  const [observaciones, setObservaciones] = useState([]);
  const [asoc, setAsoc] = useState({
    subject: "",
    description: "",
  });

  const [info, setInfo] = useState({
    subject: "",
    description: "",
    attached: "",
  });

  const abrirCerrarModalDetails = () => {
    setShowModalDetails(!showModalDetails);
  };
  const abrirCerrarModalDetails5 = () => {
    setShowModalDetails5(!showModalDetails5);
  };
  const buscarTipo = async () => {
    setLoading(true);
    const url = `${urlBase}/complaint-claim`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };
    const rtdo = await axios.get(url, { headers });
    // setdataUser(JSON.parse(localStorage.getItem('user')))
    console.log(rtdo.data.data);
    setData(rtdo.data.data);
    setLoading(false);
  };

  useEffect(() => {
    setdataUser(JSON.parse(localStorage.getItem("user")));
    setDataProperty(JSON.parse(localStorage.getItem("propiedad")));
  }, []);
  useEffect(() => {
    buscarTipo();
  }, [dataUser]);

  const buscarRespuesta = async () => {
    abrirCerrarModalDetails5();

    const url = `${urlBase}/response-association`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };

    const rtdo = await axios.get(url, { headers });
    setAsoc(
      rtdo.data.data.filter(
        (artista) => artista.complaintClaimId === info.id.toString()
      )
    );
    console.log(rtdo.data.data);
    // setData(rtdo.data.data)
  };
  const buscarRespuesta2 = async () => {
    setRespuesta({});
    const url = `${urlBase}/response`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };
    const rtdo = await axios.get(url, { headers });
    const rtdo2 = rtdo.data.data.filter(
      (artista) => artista.id === parseInt(asoc[0].responseComplaintClaimId)
    );
    setRespuesta(rtdo2[0]);
  };

  const styles = useStyles();
  useEffect(() => {
    buscarRespuesta2();
  }, [asoc]);

  const bodyDetails5 = (
    <div className={styles.modal}>
      <div className="estilosmodalDetails">
        <h1 className="text-center">Respuesta</h1>
        <div className="text-gray-600">
          <h5>Descripción: {respuesta && respuesta.subject}</h5>
          <div className="d-flex">
            <h5>Descargar</h5>
          </div>
          {respuesta.attached !== undefined &&
          respuesta.attached !== "" &&
          respuesta.attached[respuesta.attached.length - 1] !== "/" ? (
            <div className="d-flex">
              <a
                href={`${urlAvatar}/${respuesta.attached}`}
                target="_blank"
                className="linkdownload"
              >
                <i className="material-icons file_download">file_download</i>
              </a>
            </div>
          ) : (
            <div>No hay documento adjunto</div>
          )}
        </div>
        <hr />
        <h5>Todas las Respuestas</h5>
        <ul>
          {observaciones.map((observaciones) => (
            <li key={observaciones.id}>* {observaciones.subject}</li>
          ))}
        </ul>
        <div className="d-flex justify-content-between mt-3">
          <button
            className="btn1 text-center"
            onClick={() => abrirCerrarModalDetails5()}
          >
            Volver
          </button>
        </div>
      </div>
    </div>
  );


  const bodyDetails = (
    <div className={styles.modal}>
      <div className="estilosmodalDetails">
        <h1 className="text-center">{info && info.subject}</h1>
        <div className="text-gray-600">
          <h5>Descripción: {info && info.description}</h5>
          <h5>Estado: {info.state && info.state.name}</h5>
          {/* <h5 >Actualización: {(info.state&&info.state.updated_at).slice(0,10).split(" ")[0].split("-").reverse().join("-")}</h5> */}
          {info.attached !== undefined && info.attached !== "" ? (
            <div className="d-flex">
              <h5>Descargar: </h5>
              <a
                href={`${urlAvatar}/${info.attached}`}
                target="_blank"
                className="linkdownload"
              >
                <i className="material-icons file_download">file_download</i>
              </a>
            </div>
          ) : (
            <div>No hay documento adjunto</div>
          )}
        </div>

        <div className="d-flex justify-content-between mt-3">
          <button
            className="btn1 text-center"
            onClick={() => abrirCerrarModalDetails()}
          >
            Volver
          </button>
          <button
            className="btn1 text-center"
            onClick={() => buscarRespuesta()}
          >
            Ver respuesta
          </button>
        </div>
      </div>
    </div>
  );

  const seleccionarUser = (queja) => {
    setInfo(queja);
    setObservaciones(queja.response_complaint_claim);
    abrirCerrarModalDetails();
  };

  const dataQuejasOrder = data.sort(function(a, b){
    if(a.state.name.length > b.state.name.length) { return -1; }
    if(a.state.name.length < b.state.name.length) { return 1; }
    return 0;
  })

  const sortedArray = dataQuejasOrder.sort((a, b) =>  {
    var dateA = new Date(a.created_at).getTime();
    var dateB = new Date(b.created_at).getTime();
    return dateA > dateB ? 1 : -1;
      } 
    )

  return (
    <div className="Contenedor">
      <div className="verde text-center">
        {" "}
        <h1>Quejas y Reclamos</h1>
      </div>
      <div className="blanco">
        {loading ? (
          <Box sx={{ position: "absolute", left: 170, top: 400, zIndex: 1 }}>
            <CircularProgress color="success" size={80} />
          </Box>
        ) : null}
        <div className="d-flex justify-content-between">
          <div className="mx-3 py-3 text-end">
            <button className="btn2">
              <Link to="/NewReclamo" style={{ textDecoration: "none" }}>
                <NavLink
                  className="logoContainter1 text-white"
                  exact
                  to="/NewReclamo"
                  activeClassName="linkactivo"
                  style={{ textDecoration: "none" }}
                >
                  REALIZAR QUEJA O RECLAMO
                </NavLink>
              </Link>
            </button>
          </div>
        </div>

        {sortedArray.reverse().map((casa) => (
          <div key={casa.id}>
            <div className="seccionTramites">
              <div>
                <div className="titleReclamos">{casa.subject}</div>
                <div>
                  Fecha:{" "}
                  {casa.created_at
                    .slice(0, 10)
                    .split(" ")[0]
                    .split("-")
                    .reverse()
                    .join("-")}
                </div>
                <div>Estado: {casa.state.name} </div>
              </div>
              <div className="actionSpace">
                <button
                  className="linkdownload mr-5"
                  onClick={() => seleccionarUser(casa)}
                >
                  <i className="material-icons visibility">visibility</i>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <ModalDetails2
        showModalDetails={showModalDetails}
        functionShow={abrirCerrarModalDetails}
        info={info}
        bodyDetails={bodyDetails}
      />
      <ModalDetails5
        showModalDetails={showModalDetails5}
        functionShow={abrirCerrarModalDetails5}
        info={asoc}
        bodyDetails={bodyDetails5}
      />
    </div>
  );
}
