export const getHeaders = (token)=>({
    headers:{
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' +  token,
    }
});

export const getHeaderFile = (token)=>({
    headers:{
        'Content-type': 'multipart/form-data',
        'Authorization': 'Bearer ' +  token
    } 
})

export const isTypePermited = (type)=>{
    const types = ["jpg", "png", "jpeg", "pdf", "mp4", "avi"];
    if(types.includes(type))
        return true;

    return false;
}