import "../pages/general.css";

import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import ModalDetails2 from "../components/ModalDetails2";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { userContext } from "../context/UserContext";
import { urlAvatar, urlBase } from "../utils/urls";

export const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: "90%",
    height: "70%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    
  },
  iconos: {
    cursor: "pointer",
  },
  inputMaterial: {
    width: "90%",
  },
  boton:{
    color: 'white'
  }
}));

function DetalleTramites () {
  const [data, setData] = useState([]);
  const calendarRef = useRef(null);
  const { dataUser, setdataUser } = useContext(userContext);

  const [showModalDetails, setShowModalDetails] = useState(false);

  const [dataProperty, setDataProperty] = useState({});
  const [loading, setLoading] = useState(false);

  const location = useLocation()
  const params = location.state.props
  console.log(params, 'params');

  const onEventAdded = (event) => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.addEvent({
      start: moment(event.start).toDate(),
      end: moment(event.end).toDate(),
      title: info.description,
      spaceId: info.id,
      userid: dataUser.id,
    });
    console.log(event.title);
  };

  const [info, setInfo] = useState({
    title: "",
    description: "",
    file: "",
    publicationDate: "",
  });


  const abrirCerrarModalDetails = () => {
    setShowModalDetails(!showModalDetails);
  };
  const buscarTipo = async () => {
    setLoading(true);

    const url = `${urlBase}/process-observation`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };

    const rtdo = await axios.get(url, { headers });

    console.log(rtdo.data.data);
    setData(
      rtdo.data.data.filter(
        (artista) =>
          artista.owner_process.proyect.propertyId ===
          dataProperty.id.toString()
      )
    );
  };
  console.log(data);
  console.log(dataProperty.id);
  useEffect(() => {
    setdataUser(JSON.parse(localStorage.getItem("user")));
    setDataProperty(JSON.parse(localStorage.getItem("propiedad")));
  }, []);

  console.log(data);
  useEffect(() => {
    buscarTipo();
  }, [dataUser]);

  const styles = useStyles();

  return (
    <div className={styles.modal}>
      <div className="estilosmodalDetails">
        <h1 className="text-center mt-5">
          {params.proyect.name}
        </h1>
        <div className="text-gray-600 mt-5">
          <h5 className="mt-4">
            Descripción: {params.proyect.description}
          </h5>
          <h5 className="mt-3">Estado: {params.state.name}</h5>
          <h5 className="mt-3">Fecha: {params.state.created_at}</h5>
          
          <div className="mt-4 text-center d-flex justify-content-between"></div>
        </div>
      
        <div className="d-flex justify-content-center mt-5">
          <button className={`btn1 ${styles.boton}`}>
                       <Link to={{
                          pathname: "/Tramites"
                        }} style={{ textDecoration: "none", color: "white"}}
                        exact
                        >
                         Volver
                       </Link>   
          </button>
        </div>
      </div>
    </div>
  );

  
/* 
  return (
    <div className="Contenedor">
      <div className="verde text-center">
        {" "}
        <h1>Respuestas a Tramites</h1>
      </div>
      <div className="blanco">
        {data.map((casa) => (
          <div>
            <div className="seccion">
              <div className="row mt-3">
                <h2 className="text-center">
                  Tramite: {casa.owner_process.proyect.name}
                </h2>
                <h5>Respuesta:</h5>
                <div className="row mt-1">
                  <div className=" text-gray-600">
                    <h6 className="Item-Title">
                      Fecha: {moment(casa.created_at).format("YYYY-MM-DD")}{" "}
                    </h6>
                    <h6 className="Item-Title">Descripción:</h6>
                    <div className="descriptionTramites">
                      {casa.description}
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      {casa.attached !== undefined && casa.attached !== "" ? (
                        <a
                          href={`${urlAvatar}/${casa.attached}`}
                          target="_blank"
                          className="linkdownload"
                        >
                          <i className="material-icons file_download">
                            file_download
                          </i>
                        </a>
                      ) : (
                        <span>Sin documento adjunto</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="boton-centrar"></div>
              </div>
            </div>
            <hr className="linea-seccion2"></hr>
          </div>
        ))}
      </div>
      <ModalDetails2
        showModalDetails={showModalDetails}
        functionShow={abrirCerrarModalDetails}
        info={info}
        bodyDetails={bodyDetails}
      />
    </div>
  ); */
}

export default DetalleTramites;
