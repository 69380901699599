import "../pages/general.css";

import { Link, NavLink } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import ModalDetails2 from "../components/ModalDetails2";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { userContext } from "../context/UserContext";

import { obtainTramites } from "./services/obtenerTramites";
import { useCallback } from "react";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: "100%",
    height: "80%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    //   display: "grid"
  },
  iconos: {
    cursor: "pointer",
  },
  inputMaterial: {
    width: "100%",
  },
}));

function Tramites() {
  
  const [data, setData] = useState([]);
  const calendarRef = useRef(null);
  const { dataUser, setdataUser } = useContext(userContext);
  
  const [showModalDetails, setShowModalDetails] = useState(false);
  const [dataProperty, setDataProperty] = useState({});
  const [loading, setLoading] = useState(false);
  /**
   * Token de usuario
   */
  
  const onEventAdded = (event) => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.addEvent({
      start: moment(event.start).toDate(),
      end: moment(event.end).toDate(),
      title: info.description,
      spaceId: info.id,
      userid: dataUser.id,
    });
  };

  const [info, setInfo] = useState({
    title: "",
    description: "",
    file: "",
    publicationDate: "",
  });

  

  const abrirCerrarModalDetails = () => {
    setShowModalDetails(!showModalDetails);
  };
  const token = localStorage.getItem("Authorization");
  const obtenerTramites = useCallback(async () => {
    
    setLoading(true);
    const idUser = dataUser?.id;
    if(token == undefined) return null
    const consulta = await obtainTramites(idUser, token);
    if (consulta) {
      setData(consulta);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    obtenerTramites();
  }, [token]);


  const styles = useStyles();

  const bodyDetails = (
    <div className={styles.modal}>
      <div className="estilosmodalDetails">
        <h1 className="text-center mt-5">
          {info.proyect && info.proyect.name}
        </h1>
        <div className="text-gray-600 mt-5">
          <h5 className="mt-4">
            Descripción: {info.proyect && info.proyect.description}
          </h5>
          <h5 className="mt-3">Estado: {info.state && info.state.name}</h5>
          <h5 className="mt-3">Fecha: {info && info.proyectDate}</h5>
          
          <div className="mt-4 text-center d-flex justify-content-between"></div>
        </div>
        

        <div className="d-flex justify-content-center mt-5">
          <button className="btn1" onClick={() => abrirCerrarModalDetails()}>
            Volver
          </button>
        </div>
      </div>
    </div>
  );

  const seleccionarUser = (user, caso) => {
    setInfo(user);
    
    abrirCerrarModalDetails();
  };
  
  const arrayOrder = data.sort((a, b)=> {
   return a.state.name - b.state.name 
  })


  return (
    <div className="Contenedor">
      <div className="verde text-center">
        {" "}
        <h1>Tramites</h1>
      </div>
      <div className="blanco">
        <div className="d-flex justify-content-between">
          <div className="mx-3 py-3 text-end">
            <button className="btn2">
              <Link to="/NewTramite" style={{ textDecoration: "none" }}>
                <NavLink
                  className="logoContainter1 text-white"
                  exact
                  to="/NewTramite"
                  activeClassName="linkactivo"
                  style={{ textDecoration: "none" }}
                >
                  REGISTRAR TRAMITE
                </NavLink>
              </Link>
            </button>
          </div>
        </div>

        {data && arrayOrder.map((casa) => (
          <div>
            <div className="seccionTramites">
              <div className="row mt-3 ">
                <div className="d-flex justify-content-between">
                  <h6>Fecha: {casa.proyectDate}</h6>
                </div>

                <div className="row mt-3">
                  <div className="row ">
                    <div className="d-flex justify-content-between">
                      <h5 className="Item-Title">
                        Titulo: {casa.proyect.name}
                      </h5>
                      <button
                        className="linkdownload "
                        
                      >
                        <Link to={{
                          pathname: "/DetalleTramites",
                          state: {props: casa},
                        }} style={{ textDecoration: "none" }}
                        exact
                        >
                        <i className="material-icons visibility">visibility</i>
                       </Link>
                        
                      </button>
                    </div>
                  </div>
                </div>

                <div className="boton-centrar"></div>
              </div>
            </div>
            <hr className="linea-seccion2"></hr>
          </div>
        ))}
      </div>
      <ModalDetails2
        showModalDetails={showModalDetails}
        functionShow={abrirCerrarModalDetails}
        
        info={info}
        bodyDetails={bodyDetails}
      />
    </div>
  );
}

export default Tramites;
