import { makeStyles } from '@material-ui/core/styles';
import { money } from '../../../utils/formats';

const useStyles = makeStyles((theme) => ({
        modal: {
            position: 'absolute',
            width: "95%",
            height: "100%",
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        },
        iconos:{
            cursor: 'pointer'
        }, 
        inputMaterial:{
            width: '100%'
        }
    }
  )
);

const DetailGastosTable = ({concept, document, amount})=>{
    const linkDocument = `https://back2.tinpad.com.pe/public/${document}`;
    return(
        <tr>
            <td><p className="text-gray-600">{ concept }:</p></td>
            <td>{ money.format(amount) }</td>
            <td>
                <a href={linkDocument} target="_blank" className="linkdownload" >
                    <i className="material-icons visibility">visibility</i>
                </a>
            </td>
        </tr>
    )
}

const DetailGastosComunesModal = ({
    data,
    total,
    backFunction
})=>{
    const styles = useStyles();
    return(
        <div className={styles.modal}>
            <div className="mx-3 mt-5">
                <h1 className="text-center">Gastos Comunes</h1>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Concepto</th>
                            <th scope="col">Monto</th>
                            <th scope="col">Detalle</th>
                    
                        </tr>
                    </thead>
                    <tbody>
                    {data.map((casa,i)=>(<DetailGastosTable key={i} concept={casa.concept} amount={casa.amount} document={casa.document}/>))}
                    </tbody>
                </table>
                <div className="d-flex justify-content-between mt-3">
                    <h2 className="text-black">Total:</h2>
                    <h2 className="text-gray-600"> { money.format(total)}</h2>
                </div>
                <div className="d-flex justify-content-center mt-4">
                    <button className="btn1" onClick={backFunction} >VOLVER</button>
                </div>
            </div>
        </div>
    )
}
DetailGastosTable.defaultProps = {
    concept: "",
    document:"",
    amount: 0
}
DetailGastosComunesModal.defaultProps = {
    detail: [],
    total: 0,
    backFunction: ()=>{}
}
export default DetailGastosComunesModal;