import axios from "axios";
import { getHeaderFile, getHeaders } from "../../../utils/methods";
import { urlComplaintClaim } from "../../../utils/urls";

export const addReclamo = async (token, title, problem, propertyId, typeProblem, file)=>{
    let header = null;
    let data = null;
    const stateId = "3";
    if(file){
        header = getHeaderFile(token);
        data = new FormData();
        data.append("subject", title);
        data.append("description", problem);
        data.append("propertyId", propertyId);
        data.append("stateId", stateId);
        data.append("type", typeProblem);
        data.append("file", file);
    }else{
        header = getHeaders(token);
        data = {
            subject: title,
            description: problem,
            propertyId: propertyId,
            stateId: stateId,
            type: typeProblem
        }
    }
    try{
        const consulta = await axios.post(urlComplaintClaim, data, header);
        if(consulta.status === 201)
            return consulta.data.message
    }catch(e){
        console.log("Error al crear un reclamo:",e);
    }
    return false;
}