import axios from "axios";
import { getHeaders } from "../../utils/methods"
import { urlOwnerProcess } from "../../utils/urls";

export const obtainTramites = async (idUser, token )=>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.get(urlOwnerProcess,header);
        if(consulta.status === 200){
            const data = consulta.data.data;
            console.log("Data tramites:",data, idUser);
            const filterData = data.filter(itm=>itm?.proyect?.property?.users?.[0]?.id === idUser);
            return filterData;
        }
    }catch(e){
        console.log("Erro al obtener los tramites:",e);
    }
    return false;
}