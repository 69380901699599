export const configMoney = {
    MX:{
        region:"es-MX",
        configuration:{
            style: "currency",
            currency: "MXn"
        }
    },
    PE:{
        region:"es-PE",
        configuration:{
            style: "currency", 
            currency: "PEN"
        }
    }
}