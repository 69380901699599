import "../pages/general.css"

import {
  Link,
  NavLink,
} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from 'react';

import AgregarEvento from '../components/AgregarEvento'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import ModalDetails2 from "../components/ModalDetails2";
import axios from "axios"
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import deportivo from "../IMG/deportivo.png"
import esLocale from '@fullcalendar/core/locales/es';
import espacios from "../IMG/espacioscomunes.png"
import esparcimiento   from "../IMG/esparcimiento.png"
import listPlugin from '@fullcalendar/list'; //For List View
import {makeStyles} from '@material-ui/core/styles';
import moment from "moment"
import recreativos from "../IMG/recreativos.png"
import { set } from "date-fns";
import { userContext } from '../context/UserContext';
import visitas from "../IMG/VISITAS.png"
import { urlAvatar, urlBase, urlDeportiveSpace, urlReservation } from "../utils/urls";
import { getHeaders } from "../utils/methods";
import Loader from "../components/Loader";
import './EspaciosDeportivos.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    width: "100%",
    // height: "100%", 
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  //   display: "grid"
  },
  iconos:{
    cursor: 'pointer'
  }, 
  inputMaterial:{
    width: '100%'
  }
}));

function EspaciosDeportivos() {
  const [modalOpen, setModalOpen] = useState(false)
  const [data, setData] = useState([])
  const calendarRef = useRef(null)
  const { dataUser, setdataUser } = useContext(userContext);
  const [respuesta, setRespuesta] = useState("");
  const [loading, setLoading] = useState(false);
  const [titleLoading, setTitleLoading] = useState("Cargando espacios para reserva");
  const [checkList, setCheckList] = useState(false);
  const [availableInfo, setavAilableInfo] = useState(false);
  const [showModalDetails, setShowModalDetails] = useState(false);
  const [exito, setExito] = useState(false);
  /**
   * Token de sesión 
   */
  const token = localStorage.getItem('Authorization');
  /**
   * @function onEventAdded
   * @description Añadir evento
   * @param {object} event 
   */
  const onEventAdded = event => {
    setTitleLoading("Agregando evento");
    setLoading(true);
    let calendarApi = calendarRef.current.getApi()
    const body = {
      start: event.dateInit,
      end: event.dateFinish,
      title: info.description,
      spaceId: info.id,
      userid: dataUser.id  
    }
    calendarApi.addEvent(body); 
    const timer =setInterval(()=>setExito(false),3000); 
    clearInterval(timer);
    setLoading(false);
    setTitleLoading("Cargando espacios para reserva");
  }

  const abrirCerrarModalDetails=()=>{
    setShowModalDetails(!showModalDetails);
    setCheckList(false)
    setavAilableInfo(true)
  } 

  const handleEventAdd = async (data) => {
    const headers = getHeaders(token);
    const body = {
      start: moment(data.event.start).format('YYYY-MM-DD HH:mm:SS'),
      end: moment(data.event.end).format('YYYY-MM-DD HH:mm:SS'),
      title: info.description,
      spaceId: info.id,
      userId: dataUser.id
    }
    try{
      const consulta = await axios.post(urlReservation , body, headers);
      const response = consulta.data.data;
      setRespuesta(response?.message || "Reserva Exitosa")
      setExito(true);  
    }catch(e){
      alert("Erro al hacer la reservación");
      console.log("Error al añadir la reserva:",e);
    }
  }

  const buscarTipo = async() => {
    setLoading(true);
    const headers = getHeaders(token);
    try{
      const consulta = await axios.get(urlDeportiveSpace, headers);
      const response = consulta.data.data;
      const filterData = response.filter(itm=>itm?.space?.visibility === "1");
      setdataUser(JSON.parse(localStorage.getItem('user')))
      setData(filterData);
    }catch(e){
      console.log("Error obtener espacios deportivos:",e);
    }
    setLoading(false);
  }
   
  useEffect(() => {
   buscarTipo()
  }, []);

  const [info, setInfo] = useState({
    space:"",
    
    spaceId: ""
  })

  const seleccionarUser=(user, caso)=>{

    setInfo(user);
    // console.log(info.property.block);
    abrirCerrarModalDetails()

  }



  const _handleReserve = (casa) => {
    setModalOpen(true)
    abrirCerrarModalDetails()
    setInfo(casa)
  }

  const cambiarEstado = () => {
    setCheckList(!checkList)
  }
console.log(info);

const styles= useStyles();

const bodyDetails =(
  <div className={styles.modal}>

     
      <div className="estilosmodalDetails">

      {availableInfo &&  <h3>{info.description}</h3>}
     
      <h6>Terminos y Condiciones</h6>
      <div className="rulesEspacios">
        {availableInfo && info.rulesOfUse}
      </div>
      <div className="d-flex justify-content-start">
        <h6>Tiempo previo de reserva: <span className="grisdesc"> {info.previusReservationTime} hs</span> </h6>
     
      </div>
      <div className="d-flex justify-content-start">
        <h6>Horas máximas de reserva: <span className="grisdesc">{info.maximiunReservationTime} horas por mes</span> </h6>

      </div>
      </div>
      <div className="topping" onChange={cambiarEstado}>
    <input type="checkbox" /> Estoy de acuerdo
  </div>

      <div className="d-flex justify-content-around mt-3">
  {checkList && <button className="btn2" onClick={()=> _handleReserve(info)} >Reservar</button>}
        <button className="btn2" onClick={()=>abrirCerrarModalDetails()}>Volver</button>
        </div>

  </div>
  )

    return <div className="Contenedor" >
      <Loader fullScreen title={titleLoading} isShow={loading}/>
      {exito ? <div classs="container p-5">
        <div className="row no-gutters fixed-top">
          <div className="col-lg-5 col-md-12">
            <div className="alert alert-primary fade show" role="alert">
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              <h4 className="alert-heading text-center">{respuesta}</h4>
            </div>
          </div>
        </div>
      </div>: null}
      <div className='verde text-center'>  <h1>Reservar Espacio</h1></div>
      <div className='blanco'>
      <div className='FullCalendar blind'>
                <FullCalendar
                locale={esLocale}
                ref={calendarRef}
                events={data}
                plugins={[ dayGridPlugin, listPlugin ]}                
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,dayGridWeek,dayGridDay,listWeek'
                  }}
                    defaultView="dayGridMonth"
                    eventAdd={event=>handleEventAdd(event)}
                    // datesSet={(date )=> handleDateSet(date)}
                />
            </div>

            <div className="row">
                <div className="d-flex justify-content-center mt-3">
                    <div className="col-8 ml-2">
                    </div>
                    <div className="col-4 mr-2 d-flex align-items-center"><button className="btn2"><Link to="/MisReservas" style={{ textDecoration: 'none' }}>
                <NavLink className="logoContainter1 text-white" exact to="/MisReservas" activeClassName="linkactivo" style={{ textDecoration: 'none' }}>
                  
                  <h6>MIS RESERVAS</h6>
               
                </NavLink>
              </Link></button></div>
                </div>
            </div>
    {data.map(casa => (  <div>
        <div className="seccion">
          <div className="row mt-3">


            <div className="row mt-3">
              <div className="col-6">
                <img src={`${urlAvatar}/${casa?.path}`} alt="" className="foto" />
              </div>
              <div className="col-6">
                <div className="row">
                
                  <h6 className="espaciodesc">{casa.space.description}</h6>
                  <div className="d-flex justify-content-end">
                   
                    <button className="btn2a mt-5" onClick={()=>seleccionarUser(casa.space) }>  RESERVAR</button></div>
                </div>
              </div>
            </div>
            <AgregarEvento info={info} isOpen={modalOpen} onClose={()=> setModalOpen(false)} onEventAdded={event => onEventAdded(event)}/>
          </div>
          
        </div>
        <hr className="linea-seccion2"></hr>
    </div> ))}



    
    </div>
    <ModalDetails2
      showModalDetails={showModalDetails}
      functionShow= {abrirCerrarModalDetails}
      //handleChangeInsert={handleChangeInsert}
      //onSubmitEditar={onSubmitEditar}
      info={info}
      bodyDetails={bodyDetails}
    />
    
  </div>;
}

export default EspaciosDeportivos;
