import "../pages/general.css";
import "./NewReclamoPage/style/formStyle.css";

import { Button, Modal, TextField } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { userContext } from "../context/UserContext";
import { urlBase } from "../utils/urls";
import { isTypePermited } from "../utils/methods";
import Swal from "sweetalert2";
import { addReclamo } from "./NewReclamoPage/services/addReclamo";
import Loader from "../components/Loader";
import { useForm } from "react-hook-form";


const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    //   display: "grid"
  },
  iconos: {
    cursor: "pointer",
  },
  inputMaterial: {
    width: "100%",
  },
}));

function NewReclamo() {
  const [dataProperty, setDataProperty] = useState({});
  const { dataUser, setdataUser } = useContext(userContext);
  const [respuesta, setRespuesta] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFilesPost, setSelectedFilesPost] = useState();
  const [loader, setLoader] = useState(false)
  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [exito, setExito] = useState(false);
  const [info, setInfo] = useState({
    type: "",
  });
  //Token del usuario
  const token = localStorage.getItem("Authorization");

  const {register,handleSubmit,formState:{errors}}= useForm()

  useEffect(() => {
    setdataUser(JSON.parse(localStorage.getItem("user")));
    setDataProperty(JSON.parse(localStorage.getItem("propiedad")));
  }, []);

  const { description, name } = info;

  const styles = useStyles();

  const handleChangeInsert = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const creaReclamoQueja = async (e) => {
    console.log("Info:", info);
    if (!selectedFilesPost) {
      Swal.fire({
        title: "Confirmar envio",
        icon: "question",
        text: "Esta seguro que desea envar esta queja/reclamo sin documento adjunto",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "Si, enviar",
        cancelButtonText: "No, editar",
      }).then(async (resp) => {

        if (resp.isConfirmed) {
          setLoader(true)
          const consulta = await addReclamo(
            token,
            e.name,
            e.description,
            dataProperty.id,
            info.type,
            selectedFilesPost
          );
          if (consulta) {
            setLoader(false)
            Swal.fire({
              title: "Queja enviada con éxito",
              icon: "success",
              showConfirmButton: true,
              confirmButtonText: "Aceptar",
            }).then(() => {
              setSelectedFilesPost(null);
              setRedirect(true);
            });
          } else {
            setLoader(false)
            Swal.fire({
              title: "Error al enviar la queja",
              icon: "error",
            });
          }
        }
      });
    } else {
      setLoader(true)
      const consulta = await addReclamo(
        token,
        e.name,
        e.description,
        dataProperty.id,
        info.type,
        selectedFilesPost
      );
      if (consulta) {
        setLoader(false)
        Swal.fire({
          title: "Queja enviada con éxito",
          icon: "success",
          showConfirmButton: true,
          confirmButtonText: "Aceptar",
        }).then(() => {
          setSelectedFilesPost(null);
          setRedirect(true);
        });
      } else {
        setLoader(false)
        Swal.fire({
          title: "Error al enviar la queja",
          icon: "error",
        });
      }
    }
  };

  const onSubmitInsertar = (e) => {
    
    const{name,description}=e
    
    if (name.trim() === "" || info.type === "" || description === "") {
      setError(true);
    } else {
      setError(false);
      creaReclamoQueja(e);
    }
  };

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const dataFile = e.target.files[0];
      const splitFile = dataFile.name.split(".");
      const typeFile = splitFile[splitFile.length - 1];
      if (isTypePermited(typeFile)) {
        setSelectedImage(e.target.files[0]);
        console.log("File:", e.target.files[0], typeFile);
        setSelectedFilesPost(e.target.files[0]);
      } else {
        Swal.fire({
          title: "Error formato no válido",
          icon: "error",
          text: "Formatos permitidos: jpg, png, jpeg, pdf, mp4, avi",
        });
        setSelectedImage(null);
        setSelectedFilesPost(null);
      }
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage(null);
  };

  const gustos = [
    { value: "Queja", label: "Queja" },
    { value: "Reclamo", label: "Reclamo" },
  ];

  useEffect(() => {
    return () => {
      setSelectedImage(null);
    };
  }, []);

  if (redirect) {
    return <Redirect to="/TramitesyQuejas" />;
  }

  return (
    <div className="Contenedor">
      {exito ? (
        <div classs="container p-5">
          <div className="row no-gutters fixed-top">
            <div className="col-lg-5 col-md-12">
              <div className="alert alert-primary fade show" role="alert">
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="alert-heading text-center">{respuesta}</h4>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="verde text-center">
        {" "}
        <h1>Registrar Queja o Reclamo</h1>
      </div>
      <div className="blanco">
        <form action="" onSubmit={handleSubmit(onSubmitInsertar)}>
          <div className="px-5 pt-4">
           
            <TextField
              className={styles.inputMaterial}
              name="name"
              label="Titulo*"
              {...register('name',{
                required:{
                  value:true,
                  message:'El campo es requerido'
                },
                minLength:{
                  value:4,
                  message:'El titulo debe al menos 4 letras'
                }
              })}
            />
            <br />
            <span className="text-danger text-small d-block mb-2">
                {errors?.name?.message}
            </span>
            <TextField
              className={styles.inputMaterial}
              name="description"
              
              label="Descripción*"
              rows={2}
              multiline
              {...register('description',{
                required:{
                  value:true,
                  message:'El campo es requerido'
                },
                minLength:{
                  value:4,
                  message:'La descripcion al menos 4 letras'
                }
              })}
            />
            <br />
            <span className="text-danger text-small d-block mb-2">
                {errors?.descripcion?.message}
            </span>
            <div className="mt-4">
              <select
                name="type"
                className="selectInput"
                onChange={handleChangeInsert}
              >
                <option value=""> Seleccione si es queja o reclamo *</option>
                {gustos.map((fbb) => (
                  <option key={fbb.value} value={fbb.value}>
                    {fbb.label}
                  </option>
                ))}
                ;
              </select>
            </div>
            <div className="mt-5">
              <input type="file" onChange={imageChange} id="file" name="file" />
              <div className="label-holder">
                <label htmlFor="file" className="label1">
                  <i className="material-icons">attach_file</i>
                </label>
              </div>
            </div>{" "}
            <br />
            {selectedImage !== null && (
              <div className="eliminarImg">
                <h6>
                  <span className="detailsInfo">{info && info.title}</span>
                </h6>
                <div>{selectedImage.name}</div>
                <button onClick={removeSelectedImage} style={styles.delete}>
                  Eliminar
                </button>
              </div>
            )}
            <br />
            <br />
            <div className="d-flex justify-content-between">
               <Loader fullScreen={true} title={'registrando'} isShow={loader} />
              <button className="btn1" type="submit">
                REGISTRAR
              </button>
              <button className="btn1">
                <Link to="/Reclamos" style={{ textDecoration: "none" }}>
                  <NavLink
                    className="text-white"
                    to="/Reclamos"
                    activeClassName="is-active"
                    style={{ textDecoration: "none" }}
                  >
                    CANCELAR
                  </NavLink>
                </Link>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewReclamo;
