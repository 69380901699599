export const getTotalCondominiumPercent = (participation,totalCondomium) => (parseFloat(participation)*parseFloat(totalCondomium))/100;
export const getTotalLightExpediture = (lightConsume, totalLight, lightTransactionCost) => (parseFloat(lightConsume)*parseFloat(totalLight)+parseFloat(lightTransactionCost));
export const getTotalWaterExpediture = (waterConsume, totalWater, waterTransactionCost) => (parseFloat(waterConsume)*parseFloat(totalWater)+parseFloat(waterTransactionCost));
export const getExpeditureInPeriod = ({
    participation,
    total,
    lightConsume,
    totalLight,
    lightTransactionCost,
    waterConsume,
    totalWater,
    waterTransactionCost
})=>{
    const condominium = getTotalCondominiumPercent(participation, total);
    console.log("Condominium:",condominium)
    const light = getTotalLightExpediture(lightConsume,totalLight,lightTransactionCost);
    console.log("Light:",light)
    const water = getTotalWaterExpediture(waterConsume, totalWater, waterTransactionCost);
    console.log("Water: ",water)
    return condominium + light + water;
}