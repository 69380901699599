export const urlBase = process.env.REACT_APP_URL_API_TEST;
export const urlAvatar = process.env.REACT_APP_URL_STORE_TEST;
export const urlLogin = `${urlBase}/login`;
export const urlTotalLightExpediture = `${urlBase}/total-light-expenditure`;
export const urlTotalWaterExpediture = `${urlBase}/total-water-expenditure`;
export const urlLightExpediture = `${urlBase}/light-expenditure`;
export const urlWaterExpediture = `${urlBase}/water-expenditure`;
export const urlAccountBank = `${urlBase}/account-bank`;
export const urlGetRecipt = `${urlBase}/get-receipt`;
export const urlMyInvoices= `${urlBase}/my-invoices`;
export const urlPayment = `${urlBase}/payment`;
export const urlCondominiumExpense = `${urlBase}/condominium-expense`;
export const urlUploadAvatar = `${urlBase}/upload-avatar`;
export const urlUser = `${urlBase}/user`;
export const urlNewReleases = `${urlBase}/new-release`;
export const urlGuest = `${urlBase}/guest`;
export const urlGuestProvider = `${urlBase}/guest-provider`;
export const urlPropertyUser = `${urlBase}/property-user`;
export const urlReservation = `${urlBase}/reservation`;
export const urlDeportiveSpace = `${urlBase}/space-image`;
export const urlOwnerProcess = `${urlBase}/owner-process`;
export const urlComplaintClaim= `${urlBase}/complaint-claim`;