import axios from "axios";
import { getHeaders } from "../../../utils/methods";
import { urlLightExpediture, urlTotalLightExpediture } from "../../../utils/urls";

const token = localStorage.getItem('Authorization');
export const getTotalLightExpediturePerPeriod = async (period)=>{
    try{
        const header = getHeaders(token);
        const consulta = await axios.get(urlTotalLightExpediture,header);
        const response = consulta.data.data;
        const filterPeriod = response.filter(itm=>itm.date.slice(0,7) === period);
        return filterPeriod?.[0]?.consume;
    }catch(e){
        throw Error(e);
    }
}

export const getLightExpediturePerPeriod = async (period, propertyId) => {
    try{
        const header = getHeaders(token);
        const consulta = await axios.get(urlLightExpediture, header);
        const response = consulta.data.data;
        const filterPeriod = response.filter(itm=>itm.date.slice(0,7) === period && parseInt(itm.propertyId) === parseInt(propertyId));
        return filterPeriod?.[0];
    }catch(e){
        throw Error(e);
    }
}