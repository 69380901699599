import '../pages/general.css'
import "../../node_modules/react-datetime/css/react-datetime.css"
import "./styles/calendarEvents.scss";

import React, {useEffect, useState} from 'react'
import Datetime from 'react-datetime';
import Modal from "react-modal";
import Calendar from "react-calendar";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import moment from 'moment';



function AgregarEvento({isOpen, onClose, onEventAdded, info}) {
    const [title, setTitle] = useState("")
    const [start, setStart] = useState(new Date())
    const [end, setEnd] = useState(new Date())
    const [spaceId, setspaceId] = useState("");
    const [dateValue, setDateValue] = useState(new Date());
    const [hourStart, setHourStart] = useState(new Date());
    const [hourEnd, setHourEnd] = useState(new Date());

    const handleChangeStart = (val)=>{
        setHourStart(val);
        const startTime = hourStart.getTime();
        const addTime = startTime + 60*60*1000;
        setHourEnd(hourStart.setTime(addTime))
    }
    const onSubmit = (e) => {
        e.preventDefault();
        const newTime = moment(dateValue);
        const initTime= moment(hourStart).format("HH:mm:ss");
        const endTime = moment(hourEnd).format("HH:mm:ss");
        const splitInit = initTime.split(":");
        const splitFinish = endTime.split(":");
        const dateInit = newTime.set({ h:splitInit[0], m:splitInit[1] }).toDate();
        const dateFinish = newTime.set({ h:splitFinish[0], m:splitFinish[1] }).toDate();
        onEventAdded({ dateInit, dateFinish });
        onClose();
    }


    return (
        <div className='modalStyle'>
            <Modal isOpen={isOpen} onRequestClose={onClose}>
                <form action="" onSubmit={onSubmit}>
                    <h5>{info.description}</h5>
                    <div>
                        <Calendar 
                            onChange={setDateValue} 
                            value={dateValue}
                            minDate={new Date()}
                        />
                    </div>
                    <div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack spacing={2}>
                                <MobileTimePicker
                                    label="Hora de inicio"
                                    onChange={handleChangeStart}
                                    value={hourStart}
                                    renderInput={(params)=><TextField {...params} />}
                                />
                                <MobileTimePicker
                                    label="Hora final"
                                    onChange={setHourEnd}
                                    value={hourEnd}
                                    renderInput={(params)=><TextField {...params} />}
                                    minTime={hourStart}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </div>
                    
                    <div className='boton-centrar'><button className='btn1 mt-4'>Reservar Espacio</button></div>
                </form>
            </Modal>
        </div>
    )
}

export default AgregarEvento
