import { useEffect, useState } from "react"
import { money } from "../../../utils/formats";

const ModalBodyDetailGastosPage = ({
    date,
    manzana,
    lote,
    totalCondominio,
    totalIndividual,
    totalAgua,
    totalLuz,
    dataBank,
    toggleFunction,
    adjuntFunction,
    downloadRecibeFuntion,
    resumeExpeditureFuncion
})=>{
    /**
     * Controlador de los meses
     */
    const [mesSelected, setMesSelected] = useState(0);
    const [yearSelected, setYearSelected] = useState(0);
    
    useEffect(()=>{
        const initComponent = ()=>{
            const subdivideDate = date.split("-");
            setYearSelected(subdivideDate[0]);
            setMesSelected(subdivideDate[1]);
        }
        initComponent();
    },[])

    return(
        <div>
            <div className="estilosmodalDetails1">
                <div className="container">
                    <div className="row mt-2">
                        <h1 className="text-center">Nombre del Condomino</h1>
                        <div className="col-12 d-flex justify-content-between">
                            <div className="col-6">
                                <h6 className="text-gray-600">Periodo: {mesSelected}/{yearSelected}</h6>
                                <h6 className="text-gray-600">Proiedad: Mz {manzana} lt {lote}</h6>
                            </div>
                            <button className="btn1" onClick={()=>downloadRecibeFuntion()}>Descargar recibo</button>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <h4 className="text-black">Gastos comunes del Condominio</h4>
                        <div className="d-flex justify-content-between mt-1">
                            <h5 className="text-gray-600">Total: { money.format(totalCondominio) } </h5>
                            <h5 className="text-gray-600">-----</h5>
                            <div className="d-flex justify-content-center ">
                                <button className="linkdownload text-center" onClick={()=>resumeExpeditureFuncion()} >
                                    <i className="material-icons visibility">visibility</i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <h4 className="font-weight-bold text-black">Gastos de la propiedad</h4>
                        <div className="d-flex justify-content-between">
                            <h5 className="text-gray-600">Gastos Comunes: </h5>
                            <h5 className="text-gray-600">{ money.format(totalIndividual)}</h5>
                        </div>
                        <div className="d-flex justify-content-between">
                            <h5 className="text-gray-600">Servicios de Agua:</h5>
                            <h5 className="text-gray-600">{ money.format(totalAgua) }</h5>
                        </div>
                        <div className="d-flex justify-content-between">
                            <h5 className="text-gray-600">Servicios de Luz:</h5>  
                            <h5 className="text-gray-600">{ money.format(totalLuz)}</h5>      
                        </div> 
                        <div className="d-flex justify-content-between">
                            <h3 className="text-black">Total a pagar: </h3>
                            <h3 className="text-black">{ money.format(totalIndividual + totalAgua + totalLuz)}</h3>
                        </div>
                    </div>

                    <div className="row mt-1">
                        <div className="col-12">
                        {dataBank.map(data => (
                        <div>
                            <h6 className="text-gray-600">Banco: {data.title}</h6>
                            <h6 className="text-gray-600">Cuenta: {data.accountNumber}</h6>
                        </div>
                        ))}
                        </div>
                        <div className="text-center mt-2  d-flex justify-content-between" >
                            <button className="btn1 mx-1" onClick={()=>adjuntFunction()}>Adjuntar Pago</button>
                            <button className="btn1" onClick={()=>toggleFunction()}>Volver</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ModalBodyDetailGastosPage.defaultProps = {
    date:"1900-01-01",
    manzana:"0",
    lote:"0",
    totalCondominio:"0",
    totalIndividual:"0",
    totalAgua:"0",
    totalLuz:"0",
    dataBank:[],
    toggleFunction:()=>{},
    adjuntFunction:()=>{},
    downloadRecibeFuntion:()=>{},
    resumeExpeditureFuncion:()=>{}
}

export default ModalBodyDetailGastosPage;