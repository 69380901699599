import "../pages/general.css";
import { TextField } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { userContext } from "../context/UserContext";
import { urlBase } from "../utils/urls";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  iconos: {
    cursor: "pointer",
  },
  inputMaterial: {
    width: "100%",
  },
}));

function NewTramite() {
  const [respuesta, setRespuesta] = useState("");
  const { dataUser, setdataUser } = useContext(userContext);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedFilesPost, setSelectedFilesPost] = useState();
  const [error, setError] = useState(false);
  const [projectId, setprojectId] = useState("");
  const [processId, setProcessId] = useState("");
  const [listoProyecto, setListoProyecto] = useState(false);
  const [listoProyecto2, setListoProyecto2] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [exito, setExito] = useState(false);
  const [dataProperty, setDataProperty] = useState({});
  const [info, setInfo] = useState({
    name: "",
    description: "",
    projectTypeId: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setdataUser(JSON.parse(localStorage.getItem("user")));
    setDataProperty(JSON.parse(localStorage.getItem("propiedad")));
  }, []);

  const styles = useStyles();

  const handleChangeInsert = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };
 
  const peticionPost = async (e) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };

    await axios
      .post(
        `${urlBase}/project`,
        {
          name: e.titulo,
          description: e.descripcion,
          projectTypeId: info.projectTypeId,
          propertyId: dataUser.properties[0].id
        },
        { headers }
      )
      .then((response) => {
        setprojectId(response.data.data.id);
        setListoProyecto(true);
        peticionPost2(e);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const peticionPost3 = async (e) => {
    if (selectedImage) {
      const f = new FormData();

      if(processId){
        f.append("file", selectedImage);
      f.append("name", e.titulo);
      f.append("ownerProcessId", processId);
      }
      

      const headers = {
        "Content-type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("Authorization"),
      };

      const url1 = `${urlBase}/attachment-process`;
      await axios
        .post(url1, f, { headers })
        .then((response) => {
          // setdata(data.concat(response.data));

          setSelectedFilesPost();
          console.log("exito -1");
          setProcessId();
          setRedirect(true);
        })
        .catch((error) => {
          console.log(error);

          setSelectedFilesPost();
        });

      setListoProyecto2(false);
      setListoProyecto(false);
    }
  };

  const peticionPost2 = async (e) => {
    const f = new FormData();
    if (projectId) {
      f.append("title", e.titulo);
      f.append("proyectDate", info.publicationDate);
      f.append("description", e.descripcion);
      f.append("proyectId", projectId && projectId);
      f.append("stateId", "3");
    }

    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };

    const url1 = `${urlBase}/owner-process`;
    await axios
      .post(url1, f, { headers })
      .then((response) => {
        setProcessId(response.data.data.id);
        setRespuesta(response.data.message);
        setListoProyecto2(true);
        setExito(true);
        setTimeout(() => {
          setExito(false);
        }, 2000);
        peticionPost3(e);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const onSubmitInsertar = (e) => {
    const { titulo } = e;
    if (titulo.trim() === "") {
      setError(true);
      return;
    } else {
      setError(false);

      peticionPost(e);
    }
  };


  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
      console.log(e.target.files[0]);
      setSelectedFilesPost(e.target.files[0]);
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const gustos = [
    { value: 3, label: "Inst. sanitarias" },
    { value: 5, label: "Inst. eléctricas" },
    { value: 4, label: "Construcccion" },
  ];

  if (redirect) {
    return <Redirect to="/TramitesyQuejas" />;
  }

  return (
    <div className="Contenedor">
      {exito ? (
        <div classs="container p-5">
          <div className="row no-gutters fixed-top">
            <div className="col-lg-5 col-md-12">
              <div className="alert alert-primary fade show" role="alert">
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="alert-heading text-center">{respuesta}</h4>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="verde text-center">
        {" "}
        <h1>Registrar Trámite</h1>
      </div>
      <div className="blanco">
        <form action="" onSubmit={handleSubmit(onSubmitInsertar)}>
          <div className="px-5 pt-4">
            <TextField
              className={styles.inputMaterial}
              name="titulo"
              label="Titulo*"
              {...register("titulo", {
                required: {
                  value: true,
                  message: "El campo es requqerido",
                },
                minLength: {
                  value: 3,
                  message: "La descripcion debe tener mas de 3 letras",
                },
              })}
            />
            <span className="text-danger text-small d-block mb-2">
              {errors?.titulo?.message}
            </span>
            <br />
            <TextField
              className={styles.inputMaterial}
              name="descripcion"
              label="Descripcion*"
              {...register("descripcion", {
                required: {
                  value: true,
                  message: "El campo es requqerido",
                },
                minLength: {
                  value: 4,
                  message: "La descripcion debe tener mas de 4 letras",
                },
              })}
            />
            <span className="text-danger text-small d-block mb-2">
              {errors?.descripcion?.message}
            </span>
            <br />
            <select
              name="projectTypeId"
              className="mt-4"
              onChange={handleChangeInsert}
            >
              <option value=""> Seleccione tipo de Proyecto</option>
              {gustos.map((fbb) => (
                <option key={fbb.value} value={fbb.value}>
                  {fbb.label}
                </option>
              ))}
              ;
            </select>
            <label htmlFor="" className="mt-5">
              Fecha de publicación
            </label>
            <br />
            <input
              type="date"
              className={styles.inputMaterial}
              name="publicationDate"
              onChange={handleChangeInsert}
              label="Fecha de Publicación*"
            />
            <br />
            <div className="mt-5">
              <input type="file" onChange={imageChange} id="file" name="file" />
              <div className="label-holder">
                <label htmlFor="file" className="label1">
                  <i className="material-icons">attach_file</i>
                </label>
              </div>
            </div>{" "}
            <br />
            {selectedImage && (
              <div className="eliminarImg">
                <h6>
                  <span className="detailsInfo">{info && info.title}</span>
                </h6>
                <img
                  src={URL.createObjectURL(selectedImage)}
                  className="foto1"
                  alt="Thumb"
                />
                <button onClick={removeSelectedImage} style={styles.delete}>
                  Eliminar
                </button>
              </div>
            )}
            <br />
            <br />
            <div className="d-flex justify-content-between ">
              <button className="btn1" type="submit">
                REGISTRAR
              </button>
              <button className="btn1">
                {" "}
                <Link to="/Tramites" style={{ textDecoration: "none" }}>
                  <NavLink
                    className="text-white"
                    to="/Tramites"
                    activeClassName="is-active"
                    style={{ textDecoration: "none" }}
                  >
                    CANCELAR
                  </NavLink>
                </Link>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewTramite;
