import "../pages/general.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ModalDetails2 from "../components/ModalDetails2";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { userContext } from "../context/UserContext";
import { urlAvatar, urlNewReleases } from "../utils/urls";
import { getHeaders } from "../utils/methods";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    //   display: "grid"
  },
  iconos: {
    cursor: "pointer",
  },
  inputMaterial: {
    width: "100%",
  },
  itemContainer:{
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  itemSubContainer:{
    display: 'flex',
    flexDirection: 'column',
    width: '80%'
  },
  itemStyle: {
    display: 'flex',
    justifyContent: 'space-between', 
    flexDirection: 'row'
  },
  previewStyle: {
    display: 'flex',
    alignItems: 'center'
  },
  containerIcons:{
    display: "flex",
    flexDirection: "row"
  }
}));

function Comunicados() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const calendarRef = useRef(null);
  const { dataUser, setdataUser } = useContext(userContext);
  const [showModalDetails, setShowModalDetails] = useState(false);
  /**
   * Token de sesión
   */
  const token = localStorage.getItem("Authorization");

  const onEventAdded = (event) => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.addEvent({
      start: moment(event.start).toDate(),
      end: moment(event.end).toDate(),
      title: info.description,
      spaceId: info.id,
      userid: dataUser.id,
    });
  };

  const [info, setInfo] = useState({
    title: "",
    description: "",
    file: "",
    publicationDate: "",
  });

  const abrirCerrarModalDetails = () => {
    setShowModalDetails(!showModalDetails);
  };
  const obtenerComunicados = async () => {
    setdataUser(JSON.parse(localStorage.getItem("user")));
    try {
      const headers = getHeaders(token);
      const consulta = await axios.get(urlNewReleases, headers);
      const response = consulta.data.data;
      const filterData = response.filter((itm) => itm.typeReleaseId === "2");
      setData(filterData);
    } catch (e) {
      console.log("Error New Releases:", e);
    }
  };
  useEffect(() => {
    obtenerComunicados();
  }, []);

  const styles = useStyles();

  const bodyDetails = (
    <div className={styles.modal}>
      <div className="estilosmodalDetails">
        <h2 className="text-center">{info && info.title}</h2>
        <div className="mt-4 text-gray-600">
          <h6>
            Fecha: {moment(info && info.publicationDate).format("DD-MM-YYYY")}
          </h6>
          <h6 className="mt-3"> Descripción: {info && info.description}</h6>
        </div>
        <div className="mt-3 text-center">
          <a
            href={`${urlAvatar}/${info.file}`}
            target="_blank"
            className="linkdownload"
          >
            <i className="material-icons file_download">file_download</i>
          </a>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-3">
        <button className="btn1" onClick={() => abrirCerrarModalDetails()}>
          Volver
        </button>
      </div>
    </div>
  );

  const seleccionarUser = (user) => {
    setInfo(user);
    abrirCerrarModalDetails();
  };

  return (
    <div className="Contenedor">
      <div className="verde text-center">
        {" "}
        <h1>Comunicados</h1>
      </div>
      <div className="blanco">
        {loading ? (
          <Box sx={{ position: "absolute", left: 170, top: 400, zIndex: 1 }}>
            <CircularProgress color="success" size={80} />
          </Box>
        ) : null}

        {data.map((casa) => (
          <div className={styles.itemContainer} >
              <div className={styles.itemSubContainer} >
                <h5>
                  Fecha: {moment(casa.publicationDate).format("DD-MM-YYYY")}{" "}
                </h5>
                <div >
                    <div className={styles.itemStyle}>
                      <h3 className="Item-Title">{casa.title}</h3>
                      <div className={styles.containerIcons}>
                      <button
                        className="linkdownload"
                        onClick={() => seleccionarUser(casa)}
                      >
                        <i className="material-icons visibility">visibility</i>
                      </button>
                      <a
                        href={`${urlAvatar}/${casa.file}`}
                        target="_blank"
                        className={ `${styles.previewStyle} linkdownload`}  
                      >
                        <i className="material-icons file_download">
                          file_download
                        </i>
                      </a>
                      </div>     
                    </div>
                </div>

                <div className="boton-centrar"></div>
              </div>
            <hr className="linea-seccion2"></hr>
          </div>
        ))}
      </div>
      <ModalDetails2
        showModalDetails={showModalDetails}
        functionShow={abrirCerrarModalDetails}
        // handleChangeInsert={handleChangeInsert}
        // onSubmitEditar={onSubmitEditar}
        info={info}
        bodyDetails={bodyDetails}
      />
    </div>
  );
}

export default Comunicados;
