import "../pages/general.css";
import "moment/locale/es";
import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import moment from "moment";
import { userContext } from "../context/UserContext";
import { urlBase } from "../utils/urls";

function MisReservas() {
  const [arrayResult, setArrayResult] = useState()
  const [data, setData] = useState([]);
  const { dataUser, setdataUser } = useContext(userContext);


  const buscarTipo = async () => {
    const url = `${urlBase}/reservation`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };

    const rtdo = await axios.get(url, { headers });

    setData(
      rtdo.data.data.filter((artista) => artista.user.id === dataUser.id)
    );
  };
  useEffect(() => {
    setdataUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  useEffect(() => {
    buscarTipo();
  }, [dataUser]);

  useEffect(() => {
      const sortedArray = data.sort((a, b) =>  {
      var dateA = new Date(a.start).getTime();
      var dateB = new Date(b.start).getTime();
      return dateA > dateB ? 1 : -1;
        } 
      )
      const actualityDate = new Date().getTime()
      const resultArrayForDate = []
      sortedArray.map((item, index)=> {
      const dateReservation = new Date(item.start.replace(/\s/, 'T')).getTime()
        if( dateReservation >= actualityDate ){
             resultArrayForDate.push(item)
        }
      }) 
      setArrayResult(resultArrayForDate)
      
      
  }, [data])

  return (
    <div className="Contenedor">
      <div className="verde text-center">
        {" "}
        <h1>Mis Reservas</h1>
      </div>
      <div className="blanco">
        {data.length > 0 ? (
          <div>
            {arrayResult.map((casa) => (
              <div>
                <div className="seccion">
                  <div className="row mt-3">
                    <h3>{casa?.space?.description}</h3>

                    <div className="row mt-3">
                      <p className="Item-Title">
                        Inicio de reserva:{" "}
                        <span className="Item-Description">
                          {moment(casa?.start).format("LLL")} hs
                        </span>
                      </p>
                      <p className="Item-Title">
                        Fin de reserva:{" "}
                        <span className="Item-Description">
                          {moment(casa?.end).format("LLL")} hs
                        </span>
                      </p> 
                    </div>
                    <div className="row">
                      <h6 className="Item-Title">Normas de uso: </h6>
                      <div className="scroll-item">
                        <p className="Item-Description">
                          {casa?.space?.rulesOfUse}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="linea-seccion2"></hr>
              </div>
            ))}
          </div>
        ) : (
          <div className="pt-5 text-center">
            <h3>No tienes reservas para mostrar</h3>
          </div>
        )}
      </div>
    </div>
  );
}

export default MisReservas;
