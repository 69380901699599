import { CircularProgress } from "@material-ui/core";
import "./styles/loader.styles.scss";
const Loader = ({
    fullScreen,
    title,
    isShow
})=>{
    if(!isShow)
        return null;

    if(fullScreen){
        return(
            <>
                <div className="loaderCortina"></div>
                <div className="loaderDetailCortina">
                    <CircularProgress color="sucess" size={80}/>
                    <div className="title">{title}</div>
                </div>
            </>
        )
    }

    return(
        <div className="loaderNormalContainer">
            <div><CircularProgress color="sucess" size={80}/></div>
            <div className="titleContainer">
                <span className="title">{title}</span>
            </div>
        </div>
    )
}

Loader.defaultProps = {
    fullScreen: false,
    title: "",
    isShow: false
}

export default Loader;