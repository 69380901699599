import { useEffect, useState } from "react"
import { money } from "../../../utils/formats";
import "./Column.scss";

const ColumnTable = ({date, total, estado, openRecipt})=>{
    const [mes,setMes] = useState(0);
    const [year, setYear] = useState(1900);

    useEffect(()=>{
        const getPeriod = ()=>{
            const separate = date.split("-");
            setMes(separate[1]);
            setYear(separate[0]);
        }
        getPeriod();
    },[]);

    return(
        <tr className="pd8">
            <td className="itm"><h5>{mes}/{year}</h5></td>
            <td className="itm"><h5>{money.format(total)}</h5></td>
            <td className="itm"><h5>{estado}</h5></td>
            <td className="itm">
                <button className="linkdownload text-center"  onClick={()=>openRecipt()}><i className="material-icons visibility">visibility</i></button>
            </td>
        </tr>
    )

}

ColumnTable.defaultProps={
    date: "1900-01-01",
    total: 0,
    estado: "",
    openRecipt: ()=>{}
}

export default ColumnTable;